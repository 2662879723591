import { Controller } from "@hotwired/stimulus";
import { parse } from "smtp-address-parser";
import { startCase, camelCase, throttle } from "lodash";

window.emailParse = parse;
const $ = window.jQuery;

export default class extends Controller {
  static targets = ["email", "name", "company", "logo", "logoSrc"];

  static values = { host: String };

  showUrlPreview(element) {
    if (element.value === "") {
      $("#hint").addClass("hidden");
    } else {
      $("#hint").removeClass("hidden");
    }

    let html = element.value
      .toLowerCase()
      .replace(/(https?:\/\/|www\.|\.com)/g, "")
      .replace(/\W+/g, "-")
      .toLowerCase();
    html = `Your sign in URL will be:<br /><strong>https://${html}.${this.hostValue}</strong>`;

    document.querySelector("#hint").innerHTML = html;
  }

  initUrlPreview() {
    const $this = this;
    var tenantName = document.querySelector("#tenant_name");
    tenantName.addEventListener("keyup", function (e) {
      $this.showUrlPreview(e.target);
    });
    $this.showUrlPreview(tenantName);

    var registerButton = document.querySelector("#registerButton");
    registerButton.addEventListener("click", function () {
      var tenant_name = document.getElementById("tenant_name").value;
      var regexp = /^[a-z](?:[a-z0-9-]*[a-z0-9])?$/;
      regexp.exec(tenant_name);
    });
  }

  parseEmail(e) {
    if (e.target.value) {
      try {
        var parsed = parse(e.target.value);
      } catch (error) {
        console.log(error);
      }
    }

    if (parsed) {
      var [company, tld] = parsed.domainPart.DomainName.split(".");

      if (!tld || tld.length < 2) {
        return false;
      }

      if (["gmail", "yahoo", "hotmail", "aol"].includes(company)) {
        return false;
      }

      var fullName = parsed.localPart.DotString.replace(/\W+/g, " ")
        .replace(/\d+/, "")
        .trim();
      if (fullName.split(" ").length > 1) {
        this.nameTarget.value = startCase(
          camelCase(
            parsed.localPart.DotString.replace(/\W+/g, " ").replace(/\d+/, "")
          )
        );
      }

      this.companyTarget.value = startCase(camelCase(company));

      this.nameTarget.closest(".field").classList.remove("hidden");
      this.companyTarget.closest(".field").classList.remove("hidden");

      if (parsed.domainPart.DomainName != this.logoTarget.src) {
        this.logoTarget.src = `//logo.clearbit.com/${parsed.domainPart.DomainName}`;
        this.logoSrcTarget.value = `https://logo.clearbit.com/${parsed.domainPart.DomainName}`;
      }

      this.showUrlPreview(this.companyTarget);
    } else {
      this.nameTarget.value = "";
      this.companyTarget.value = "";
      this.showUrlPreview(this.companyTarget);
      this.logoTarget.src = "";
      this.logoSrcTarget.value = "";
    }
  }

  clearbitError() {
    $(".clearbit").hide();
  }

  initValidation() {
    $("#new_brand").form({
      inline: true,
      errorFocus: false,
      on: "blur",
      fields: {
        name: {
          identifier: "Full name",
          rules: [
            {
              type: "empty",
              prompt: "Please enter your name",
            },
          ],
        },
        email: {
          identifier: "E-mail",
          rules: [{ type: "empty" }, { type: "email" }],
        },
        password: {
          identifier: "password",
          rules: [{ type: "empty" }, { type: "minLength[8]" }],
        },
        password_confirmation: {
          rules: [
            {
              type: "match[password]",
              prompt: "This has to match the password field",
            },
          ],
        },
        tos: {
          rules: [
            {
              type: "checked",
              prompt: "You must accept our terms",
            },
          ],
        },
      },
    });
  }

  initialize() {
    this.parseEmail = throttle(this.parseEmail, 200);
  }

  connect() {
    this.initValidation();
    this.initUrlPreview();
  }

}
